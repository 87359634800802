$breakpoint-mobile: 768px;
$breakpoint-desktop: 769px;

@mixin respond-to($breakpoint) {
  @if $breakpoint == mobile {
    @media screen and (max-width: $breakpoint-mobile) {
      @content;
    }
  } @else if $breakpoint == desktop {
    @media screen and (min-width: $breakpoint-desktop) {
      @content;
    }
  }
}
