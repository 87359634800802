@import "./mixins.scss";

//Fonts
$font-main: "Montserrat";
$font-title: "Montserrat Alternates";

//Font sizes
$normal-font-desktop: 16px;
$title-one-desktop: 40px;
$title-two-desktop: 28px;
$title-three-desktop: 24px;
$title-four-desktop: 20px;

$normal-font-mobile: 14px;
$title-one-mobile: 32px;
$title-two-mobile: 24px;
$title-three-mobile: 20px;
$title-four-mobile: 16px;
