.links {
  padding: 160px 128px 0px 128px;
  text-align: center;

  @include respond-to(mobile) {
    padding: 120px 16px;
  }

  .links__content {
    display: flex;
    gap: 64px;

    @include respond-to(mobile) {
      flex-direction: column;
      gap: 32px;
    }

    .links__content__image {
      flex: 1;
    }

    .links__content__ways {
      flex: 1;
      display: flex;
      gap: 32px;
      flex-direction: column;
      align-items: center;

      .links__content__ways__title {
      }

      .links__content__ways__description {
      }

      .links__content__ways__list {
        box-sizing: border-box;
        flex: 1;
        display: flex;
        gap: 16px;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .links__content__ways__list__element {
          width: 100%;
          height: 48px;
          max-width: 600px;

          a {
            display: inline-block;
            background-color: var(--item);
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            gap: 8px;
            text-decoration: none;

            &:hover {
              background-color: var(--item-hover);
            }

            &:active {
              background-color: var(--item-active);
            }

            svg:nth-child(3) {
              scale: 0.8;
              translate: -4px -4px;
            }
          }
        }
      }
    }
  }
}
