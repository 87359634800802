.contact {
  .contact__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 64px;

    @include respond-to(mobile) {
      grid-template-columns: 1fr;
      gap: 32px;
    }

    .contact__content__image {
      position: relative;
      align-self: start;

      @include respond-to(mobile) {
        width: 72%;
        justify-self: center;

        img {
          width: 100%;
          margin: auto;
        }
      }

      &::after {
        content: "";
        background-color: var(--action);
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        opacity: 16%;
        border-radius: 50%;
      }

      &::before {
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        box-shadow: 0 0 32px var(--action);
        border-radius: 50%;
        opacity: 32%;
      }

      img {
        width: 100%;
      }
    }

    .contact__content__ways {
      width: 100%;
      display: flex;
      gap: 32px;
      flex-direction: column;
      .contact__content__ways__title {
      }

      .contact__content__ways__description {
      }

      .contact__content__ways__list {
        box-sizing: border-box;
        flex: 1;
        display: flex;
        gap: 16px;
        flex-direction: column;
        align-items: center;

        .contact__content__ways__list__element {
          width: 100%;
          height: 48px;
          max-width: unset;

          a {
            display: inline-block;
            background-color: var(--item);
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            gap: 8px;
            text-decoration: none;

            &:hover {
              background-color: var(--item-hover);
            }

            &:active {
              background-color: var(--item-active);
            }

            svg:nth-child(3) {
              scale: 0.8;
              translate: -4px -4px;
            }
          }
        }
      }
    }
  }
}
