@import "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap";
@import "https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap";
@import "https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap";
body.light-mint {
  --app-background: #f9fefd;
  --subtle-background: #f2fbf9;
  --item: #ddf9f2;
  --item-hover: #c8f4e9;
  --item-active: #b3ecde;
  --static-border: #9ce0d0;
  --interactive-border: #7ecfbd;
  --active-border: #4cbba5;
  --action: #86ead4;
  --action-hover: #7de0cb;
  --low-text: #027864;
  --high-text: #16433c;
  --button-text: #000;
  --transparent-background: rgba(249 254 253 0.6);
}
body.dark-mint {
  --app-background: #0e1515;
  --subtle-background: #0f1b1b;
  --item: #092c2b;
  --item-hover: #003a38;
  --item-active: #004744;
  --static-border: #105650;
  --interactive-border: #1e685f;
  --active-border: #277f70;
  --action: #86ead4;
  --action-hover: #a8f5e5;
  --low-text: #58d5ba;
  --high-text: #c4f5e1;
  --button-text: #000;
  --transparent-background: rgba(14 21 21 0.6);
}
body.dark-jade {
  --app-background: #0d1512;
  --subtle-background: #121c18;
  --item: #0f2e22;
  --item-hover: #0b3b2c;
  --item-active: #114837;
  --static-border: #1b5745;
  --interactive-border: #246854;
  --active-border: #2a7e68;
  --action: #29a383;
  --action-hover: #27b08b;
  --low-text: #1fd8a4;
  --high-text: #adf0d4;
  --button-text: #fff;
  --transparent-background: rgba(13 21 18 0.6);
}
body.light-jade {
  --app-background: #fbfefd;
  --subtle-background: #f4fbf7;
  --item: #e6f7ed;
  --item-hover: #d6f1e3;
  --item-active: #c3e9d7;
  --static-border: #acdec8;
  --interactive-border: #8bceb6;
  --active-border: #56ba9f;
  --action: #29a383;
  --action-hover: #26997b;
  --low-text: #208368;
  --high-text: #1d3b31;
  --button-text: #fff;
  --transparent-background: rgba(13 21 18 0.6);
}
body.light-ruby {
  --app-background: #fffcfd;
  --subtle-background: #fff7f8;
  --item: #feeaed;
  --item-hover: #ffdce1;
  --item-active: #ffced6;
  --static-border: #f8bfc8;
  --interactive-border: #efacb8;
  --active-border: #e592a3;
  --action: #e54666;
  --action-hover: #dc3b5d;
  --low-text: #ca244d;
  --high-text: #64172b;
  --button-text: #fff;
  --transparent-background: rgba(255 252 253 0.6);
}
body.dark-ruby {
  --app-background: #191113;
  --subtle-background: #1e1517;
  --item: #3a141e;
  --item-hover: #4e1325;
  --item-active: #5e1a2e;
  --static-border: #6f2539;
  --interactive-border: #883447;
  --active-border: #b3445a;
  --action: #e54666;
  --action-hover: #ec5a72;
  --low-text: #ff949d;
  --high-text: #fed2e1;
  --button-text: #fff;
  --transparent-background: rgba(25 17 19 0.6);
}
body.dark-amber {
  --app-background: #16120c;
  --subtle-background: #1d180f;
  --item: #302008;
  --item-hover: #3f2700;
  --item-active: #4d3000;
  --static-border: #5c3d05;
  --interactive-border: #714f19;
  --active-border: #8f6424;
  --action: #ffc53d;
  --action-hover: #ffd60a;
  --low-text: #ffca16;
  --high-text: #ffe7b3;
  --button-text: #000;
  --transparent-background: rgba(25 17 19 0.6);
}
body.light-amber {
  --app-background: #fefdfb;
  --subtle-background: #fefbe9;
  --item: #fff7c2;
  --item-hover: #ffee9c;
  --item-active: #fbe577;
  --static-border: #f3d673;
  --interactive-border: #e9c162;
  --active-border: #e2a336;
  --action: #ffc53d;
  --action-hover: #ffba18;
  --low-text: #ab6400;
  --high-text: #4f3422;
  --button-text: #000;
  --transparent-background: rgba(25 17 19 0.6);
}
body.light-indigo {
  --app-background: #fdfdfe;
  --subtle-background: #f7f9ff;
  --item: #edf2fe;
  --item-hover: #e1e9ff;
  --item-active: #d2deff;
  --static-border: #c1d0ff;
  --interactive-border: #abbdf9;
  --active-border: #8da4ef;
  --action: #3e63dd;
  --action-hover: #3358d4;
  --low-text: #3a5bc7;
  --high-text: #1f2d5c;
  --button-text: #fff;
  --transparent-background: rgba(253 253 254 0.6);
}
body.dark-indigo {
  --app-background: #11131f;
  --subtle-background: #141726;
  --item: #182449;
  --item-hover: #1d2e62;
  --item-active: #253974;
  --static-border: #304384;
  --interactive-border: #3a4f97;
  --active-border: #435db1;
  --action: #3e63dd;
  --action-hover: #5472e4;
  --low-text: #9eb1ff;
  --high-text: #d6e1ff;
  --button-text: #fff;
  --transparent-background: rgba(17 19 31 0.6);
}
body.light-olive {
  --app-background: #fcfdfc;
  --subtle-background: #f8faf8;
  --item: #eff1ef;
  --item-hover: #e7e9e7;
  --item-active: #dfe2df;
  --static-border: #d7dad7;
  --interactive-border: #cccfcc;
  --active-border: #b9bcb8;
  --action: #898e87;
  --action-hover: #7f847d;
  --low-text: #60655f;
  --high-text: #1d211c;
  --button-text: #fff;
  --transparent-background: rgba(252 253 252 0.6);
}
body.dark-olive {
  --app-background: #111210;
  --subtle-background: #181917;
  --item: #212220;
  --item-hover: #282a27;
  --item-active: #2f312e;
  --static-border: #383a36;
  --interactive-border: #454843;
  --active-border: #5c625b;
  --action: #687066;
  --action-hover: #767d74;
  --low-text: #afb5ad;
  --high-text: #eceeec;
  --button-text: #fff;
  --transparent-background: rgba(17 18 16 0.6);
}

@media (prefers-reduced-motion: no-preference) {
  @keyframes element-click {
    0% {
      left: -100%;
      opacity: 0%;
    }
    100% {
      left: 0%;
      opacity: 100%;
    }
  }
  @keyframes text-fade-in {
    to {
      translate: 0 0;
      scale: 1;
      opacity: 1;
    }
  }
  @keyframes fade-out {
    50%, 100% {
      opacity: 0;
      translate: 0 -40px;
    }
  }
  @keyframes hero-background-animation {
    85%, 100% {
      opacity: 0;
      scale: 3;
    }
  }
  @keyframes image-fade-in {
    to {
      opacity: 1;
      scale: 1;
    }
  }
  @keyframes background-fade-in {
    to {
      opacity: 32%;
      translate: 0 0;
    }
  }
  @keyframes story-timeline {
    from {
      left: 100%;
    }
    to {
      left: 0px;
    }
  }
  @keyframes interactive-element-pop-up {
    to {
      scale: 1;
    }
  }
  @keyframes skills-headers-pop-up {
    0% {
      translate: 0 96px;
    }
    40%, 60% {
      translate: 0 -32px;
    }
    80% {
      translate: 0 16px;
    }
    100% {
      translate: 0 0;
    }
  }
  @keyframes first-project {
    from {
      scale: 0.6;
      translate: 320px 80px;
      opacity: 0;
    }
    to {
      scale: 0.9;
      translate: 160px 0px;
      opacity: 80%;
    }
  }
  @keyframes second-project {
    from {
      scale: 0.7;
      translate: 0px 80px;
      opacity: 0;
    }
    to {
      scale: 1;
      translate: 0px 0px;
      opacity: 100%;
    }
  }
  @keyframes third-project {
    from {
      scale: 0.6;
      translate: -320px 80px;
      opacity: 0;
    }
    to {
      scale: 0.9;
      translate: -160px 0px;
      opacity: 80%;
    }
  }
  @keyframes settings-modal-animation {
    from {
      scale: 0;
      opacity: 0;
    }
    to {
      scale: 1;
      opacity: 1;
    }
  }
  @keyframes translating {
    0% {
      opacity: 1;
      filter: blur(0px);
    }
    25%, 75% {
      opacity: 0.8;
      filter: blur(16px);
    }
    90%, 100% {
      opacity: 1;
      filter: blur(0px);
    }
  }
  .--translating {
    animation-name: translating;
    animation-duration: 200ms;
    animation-timing-function: linear;
  }
  .settings-modal {
    animation-name: settings-modal-animation;
    animation-duration: 200ms;
    animation-timing-function: linear;
    transform-origin: 0px;
  }
  .projects__content__list__card {
    animation-timeline: view();
    animation-timing-function: linear;
    animation-range-start: entry;
    animation-range-end: entry 240px;
  }
  .projects__content__list__card:nth-child(1) {
    animation-name: first-project;
  }
  .projects__content__list__card:nth-child(2) {
    animation-name: second-project;
  }
  .projects__content__list__card:nth-child(3) {
    animation-name: third-project;
  }
}
@media screen and (prefers-reduced-motion: no-preference) and (min-width: 769px) {
  .skills__content__headers__element {
    animation-name: skills-headers-pop-up;
    animation-timeline: view();
    animation-fill-mode: forwards;
    animation-timing-function: linear;
  }
  .skills__content__headers__element:nth-child(1) {
    animation-range-end: entry 288px;
    animation-range-start: entry -96px;
  }
  .skills__content__headers__element:nth-child(2) {
    animation-range-end: entry 320px;
    animation-range-start: entry -64px;
  }
  .skills__content__headers__element:nth-child(3) {
    animation-range-end: entry 352px;
    animation-range-start: entry -32px;
  }
  .skills__content__headers__element:nth-child(4) {
    animation-range-end: entry 384px;
    animation-range-start: entry 0px;
  }
}
@media (prefers-reduced-motion: no-preference) {
  .--interactive-element-pop-up {
    scale: 0;
    animation-name: interactive-element-pop-up;
    animation-timeline: view();
    animation-range-start: entry 150px;
    animation-range-end: entry 300px;
    animation-fill-mode: forwards;
  }
  .--item-pop-up {
    scale: 0;
    animation-name: interactive-element-pop-up;
    animation-timeline: view();
    animation-range-start: entry 20px;
    animation-range-end: entry 120px;
    animation-fill-mode: forwards;
  }
  .story__content__timeline {
    animation-name: story-timeline;
    animation-timeline: view();
    animation-range-start: entry;
    animation-range-end: entry 240px;
    animation-timing-function: ease-out;
  }
  .--background-fade-in {
    translate: 0 80px;
    animation-name: background-fade-in;
    animation-timeline: view();
    animation-range: entry;
    animation-fill-mode: forwards;
  }
  .hero__background {
    animation-name: hero-background-animation;
    animation-timeline: view();
    animation-range: exit;
  }
  .--animated {
    animation-duration: 500ms;
    animation-timing-function: ease-out;
    animation-delay: 0ms;
    animation-fill-mode: forwards;
  }
  .--text-fade-in {
    translate: 0 240px;
    opacity: 0;
    animation-name: text-fade-in;
  }
  .--image-fade-in {
    animation-name: image-fade-in;
    opacity: 0;
    scale: 0.8;
  }
  .--view-animated {
    animation-timing-function: linear;
    animation-timeline: view();
  }
  .--fade-out {
    animation-name: fade-out;
  }
  .--exit-view {
    animation-range: exit -200px;
    transform-origin: top;
  }
  .--entry-text {
    animation-range-start: entry 20px;
    animation-range-end: entry 240px;
    animation-fill-mode: forwards;
  }
  .--entry-item {
    animation-range-start: entry 20px;
    animation-range-end: entry 120px;
    animation-fill-mode: forwards;
  }
  .--entry-picture {
    animation-range-start: entry 20%;
    animation-range-end: entry;
    animation-fill-mode: forwards;
  }
}
.header {
  display: flex;
  justify-content: flex-end;
  padding: 48px 64px 16px 64px;
  width: 100vw;
  box-sizing: border-box;
  align-items: center;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  transition: all 100ms linear;
}
@media screen and (max-width: 768px) {
  .header {
    align-items: center;
    padding: 16px;
    left: 0;
    transform: unset;
  }
}
.header.header--locked {
  padding: 8px 64px 8px 64px;
  background-color: var(--transparent-background);
  border-bottom: 1px solid var(--static-border);
  box-shadow: 0 0px 8px var(--static-border);
  backdrop-filter: blur(16px);
  transition: all 200ms linear;
}
@media screen and (max-width: 768px) {
  .header.header--locked {
    padding: 8px 16px;
  }
  .header.header--locked .header__logo {
    font-size: 32px;
  }
}
@media screen and (min-width: 769px) {
  .header.header--locked .header__nav__menu__element::before {
    content: "";
    background-color: var(--item);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
    opacity: 0;
    transition: all 200ms ease;
  }
  .header.header--locked:hover .header__nav__menu__element::before {
    opacity: 100%;
  }
}
.header .header__logo {
  font-family: "Space Mono";
  font-size: 40px;
  margin-right: auto;
  transition: all 200ms linear;
}
@media screen and (max-width: 768px) {
  .header .header__logo {
    order: 1;
  }
}
.header .header__logo a {
  text-decoration: none;
}
@media screen and (max-width: 768px) {
  .header .header__nav {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    order: 3;
  }
}
.header .header__nav .header__nav__opener {
  display: none;
}
@media screen and (max-width: 768px) {
  .header .header__nav .header__nav__opener {
    display: block;
    border-radius: 50%;
    transition: all 200ms ease-out;
    padding: 8px;
    box-sizing: border-box;
    height: 40px;
    width: 40px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 30;
  }
  .header .header__nav .header__nav__opener > svg {
    width: 24px;
    height: 24px;
    color: var(--high-text);
    opacity: 100%;
    transition: all 200ms linear;
  }
  .header .header__nav .header__nav__opener.--changing > svg {
    opacity: 0%;
    scale: 0;
  }
  .header .header__nav .header__nav__opener:hover {
    background-color: transparent;
  }
  .header .header__nav .header__nav__opener:active {
    background-color: transparent;
  }
}
.header .header__nav .header__nav__menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  align-items: center;
  gap: 8px;
  padding: 0;
  margin: 0;
  list-style-type: none;
}
@media screen and (max-width: 768px) {
  .header .header__nav .header__nav__menu {
    grid-template-columns: 1fr;
    position: absolute;
    width: 128px;
    top: 44px;
    transition: all 300ms ease-out;
  }
  .header .header__nav .header__nav__menu.--close {
    right: -200px;
    top: -16px;
    padding: 64px 16px 16px 16px;
    height: 100vh;
    grid-template-rows: repeat(auto-fit, 40px);
  }
  .header .header__nav .header__nav__menu.--open {
    background-color: var(--item);
    border-left: 1px solid var(--static-border);
    box-shadow: 0 0px 8px var(--static-border);
    right: -16px;
    top: -26px;
    padding: 80px 16px 16px 16px;
    height: 100vh;
    grid-template-rows: repeat(auto-fit, 40px);
    z-index: 30;
  }
}
.header .header__nav .header__nav__menu .header__nav__menu__element {
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: all 100ms ease;
}
@media screen and (max-width: 768px) {
  .header .header__nav .header__nav__menu .header__nav__menu__element {
    text-align: left;
  }
  .header .header__nav .header__nav__menu .header__nav__menu__element a {
    width: 100%;
    box-sizing: border-box;
  }
}
.header .header__nav .header__nav__menu .header__nav__menu__element:hover::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: -100%;
  z-index: -1;
  background-color: var(--item-hover);
  animation-name: element-click;
  animation-duration: 200ms;
  animation-timing-function: ease-out;
  animation-delay: 0ms;
  animation-fill-mode: forwards;
}
.header .header__nav .header__nav__menu .header__nav__menu__element:active {
  background-color: var(--item-active);
}
.header .header__nav .header__nav__menu .header__nav__menu__element a {
  display: inline-block;
  padding: 8px;
  text-decoration: none;
}
.header .toggle-button {
  padding: 0;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 400ms linear;
  margin-left: 11px;
}
@media screen and (max-width: 768px) {
  .header .toggle-button {
    margin-left: 8px;
  }
}
.header .header__nav-background {
  display: none;
}

@media screen and (max-width: 768px) {
  .header:has(.header__nav__menu) .header__nav-background {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: var(--subtle-background);
    opacity: 0%;
    transition: all 200ms linear;
  }
  .header:has(.header__nav__menu.--open) .header__nav-background {
    opacity: 80%;
  }
}
.header:has(+ .links) .header__nav .header__nav__menu .header__nav__menu__element {
  display: none;
}

.header--locked:hover .settings-button {
  background-color: var(--item);
}
.header--locked:hover .settings-button:hover {
  background-color: var(--item-hover);
}
.header--locked:hover .settings-button:active {
  background-color: var(--item-active);
}

.settings-button {
  background-color: transparent;
  color: var(--high-text);
  padding: 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.settings-button:hover {
  background-color: var(--item-hover);
}
.settings-button:active {
  background-color: var(--item-active);
}
.settings-button svg {
  width: 20px;
  height: 20px;
}
@media screen and (max-width: 768px) {
  .settings-button {
    right: 16px;
    top: 32px;
    order: 2;
  }
}

footer {
  margin-top: 100px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 32px;
}

@media (prefers-reduced-motion: no-preference) {
  @keyframes element-click {
    0% {
      left: -100%;
      opacity: 0%;
    }
    100% {
      left: 0%;
      opacity: 100%;
    }
  }
  @keyframes text-fade-in {
    to {
      translate: 0 0;
      scale: 1;
      opacity: 1;
    }
  }
  @keyframes fade-out {
    50%, 100% {
      opacity: 0;
      translate: 0 -40px;
    }
  }
  @keyframes hero-background-animation {
    85%, 100% {
      opacity: 0;
      scale: 3;
    }
  }
  @keyframes image-fade-in {
    to {
      opacity: 1;
      scale: 1;
    }
  }
  @keyframes background-fade-in {
    to {
      opacity: 32%;
      translate: 0 0;
    }
  }
  @keyframes story-timeline {
    from {
      left: 100%;
    }
    to {
      left: 0px;
    }
  }
  @keyframes interactive-element-pop-up {
    to {
      scale: 1;
    }
  }
  @keyframes skills-headers-pop-up {
    0% {
      translate: 0 96px;
    }
    40%, 60% {
      translate: 0 -32px;
    }
    80% {
      translate: 0 16px;
    }
    100% {
      translate: 0 0;
    }
  }
  @keyframes first-project {
    from {
      scale: 0.6;
      translate: 320px 80px;
      opacity: 0;
    }
    to {
      scale: 0.9;
      translate: 160px 0px;
      opacity: 80%;
    }
  }
  @keyframes second-project {
    from {
      scale: 0.7;
      translate: 0px 80px;
      opacity: 0;
    }
    to {
      scale: 1;
      translate: 0px 0px;
      opacity: 100%;
    }
  }
  @keyframes third-project {
    from {
      scale: 0.6;
      translate: -320px 80px;
      opacity: 0;
    }
    to {
      scale: 0.9;
      translate: -160px 0px;
      opacity: 80%;
    }
  }
  @keyframes settings-modal-animation {
    from {
      scale: 0;
      opacity: 0;
    }
    to {
      scale: 1;
      opacity: 1;
    }
  }
  @keyframes translating {
    0% {
      opacity: 1;
      filter: blur(0px);
    }
    25%, 75% {
      opacity: 0.8;
      filter: blur(16px);
    }
    90%, 100% {
      opacity: 1;
      filter: blur(0px);
    }
  }
  .--translating {
    animation-name: translating;
    animation-duration: 200ms;
    animation-timing-function: linear;
  }
  .settings-modal {
    animation-name: settings-modal-animation;
    animation-duration: 200ms;
    animation-timing-function: linear;
    transform-origin: 0px;
  }
  .projects__content__list__card {
    animation-timeline: view();
    animation-timing-function: linear;
    animation-range-start: entry;
    animation-range-end: entry 240px;
  }
  .projects__content__list__card:nth-child(1) {
    animation-name: first-project;
  }
  .projects__content__list__card:nth-child(2) {
    animation-name: second-project;
  }
  .projects__content__list__card:nth-child(3) {
    animation-name: third-project;
  }
}
@media screen and (prefers-reduced-motion: no-preference) and (min-width: 769px) {
  .skills__content__headers__element {
    animation-name: skills-headers-pop-up;
    animation-timeline: view();
    animation-fill-mode: forwards;
    animation-timing-function: linear;
  }
  .skills__content__headers__element:nth-child(1) {
    animation-range-end: entry 288px;
    animation-range-start: entry -96px;
  }
  .skills__content__headers__element:nth-child(2) {
    animation-range-end: entry 320px;
    animation-range-start: entry -64px;
  }
  .skills__content__headers__element:nth-child(3) {
    animation-range-end: entry 352px;
    animation-range-start: entry -32px;
  }
  .skills__content__headers__element:nth-child(4) {
    animation-range-end: entry 384px;
    animation-range-start: entry 0px;
  }
}
@media (prefers-reduced-motion: no-preference) {
  .--interactive-element-pop-up {
    scale: 0;
    animation-name: interactive-element-pop-up;
    animation-timeline: view();
    animation-range-start: entry 150px;
    animation-range-end: entry 300px;
    animation-fill-mode: forwards;
  }
  .--item-pop-up {
    scale: 0;
    animation-name: interactive-element-pop-up;
    animation-timeline: view();
    animation-range-start: entry 20px;
    animation-range-end: entry 120px;
    animation-fill-mode: forwards;
  }
  .story__content__timeline {
    animation-name: story-timeline;
    animation-timeline: view();
    animation-range-start: entry;
    animation-range-end: entry 240px;
    animation-timing-function: ease-out;
  }
  .--background-fade-in {
    translate: 0 80px;
    animation-name: background-fade-in;
    animation-timeline: view();
    animation-range: entry;
    animation-fill-mode: forwards;
  }
  .hero__background {
    animation-name: hero-background-animation;
    animation-timeline: view();
    animation-range: exit;
  }
  .--animated {
    animation-duration: 500ms;
    animation-timing-function: ease-out;
    animation-delay: 0ms;
    animation-fill-mode: forwards;
  }
  .--text-fade-in {
    translate: 0 240px;
    opacity: 0;
    animation-name: text-fade-in;
  }
  .--image-fade-in {
    animation-name: image-fade-in;
    opacity: 0;
    scale: 0.8;
  }
  .--view-animated {
    animation-timing-function: linear;
    animation-timeline: view();
  }
  .--fade-out {
    animation-name: fade-out;
  }
  .--exit-view {
    animation-range: exit -200px;
    transform-origin: top;
  }
  .--entry-text {
    animation-range-start: entry 20px;
    animation-range-end: entry 240px;
    animation-fill-mode: forwards;
  }
  .--entry-item {
    animation-range-start: entry 20px;
    animation-range-end: entry 120px;
    animation-fill-mode: forwards;
  }
  .--entry-picture {
    animation-range-start: entry 20%;
    animation-range-end: entry;
    animation-fill-mode: forwards;
  }
}
.hero {
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.hero .hero__content {
  max-width: 600px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 32px;
}
.hero .hero__content .hero__content__action {
  margin-top: 32px;
}
.hero .hero__content .hero__content__action a {
  text-decoration: none;
}
.hero .hero__background {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0%;
  z-index: -2;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 16%;
}

.story {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 64px;
  position: relative;
  overflow: hidden;
}
.story .story__background {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 100%;
}
.story .story__background::after {
  content: "";
  background-color: var(--action);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 32%;
}
.story .story__title {
  z-index: 2;
}
.story .story__content {
  position: relative;
  width: 100%;
  height: 320px;
}
@media screen and (max-width: 768px) {
  .story .story__content {
    height: 400px;
  }
}
.story .story__content__timeline {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 64px;
  position: absolute;
  left: 0px;
  top: 0;
  transition: all 300ms ease-out;
}
@media screen and (max-width: 768px) {
  .story .story__content__timeline {
    height: 400px;
  }
}
.story .story__content__timeline .story__content__timeline__card > * {
  transition: all 100ms ease;
}
.story .story__content__timeline.story__content__timeline--moving .story__content__timeline__card > * {
  filter: blur(4px);
}
.story .story__content__timeline .story__content__timeline__card {
  background-color: var(--transparent-background);
  border: 1px solid var(--static-border);
  box-shadow: 0 0px 8px var(--static-border);
  border-radius: 8px;
  backdrop-filter: blur(8px);
  box-sizing: border-box;
  height: 100%;
  width: 480px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
@media screen and (max-width: 768px) {
  .story .story__content__timeline .story__content__timeline__card {
    width: 90vw;
  }
}
.story .story__content__timeline .story__content__timeline__card .story__content__timeline__card__content {
  display: flex;
  gap: 32px;
}
@media screen and (max-width: 768px) {
  .story .story__content__timeline .story__content__timeline__card .story__content__timeline__card__content {
    flex-direction: column;
  }
}
.story .story__content__timeline .story__content__timeline__card .story__content__timeline__card__content .story__content__timeline__card__content__picture {
  width: 120px;
}
.story .story__content__timeline .story__content__timeline__card .story__content__timeline__card__content .story__content__timeline__card__content__picture svg {
  fill: var(--action);
  transition: all 200ms linear;
}
@media screen and (max-width: 768px) {
  .story .story__content__timeline .story__content__timeline__card .story__content__timeline__card__content .story__content__timeline__card__content__picture {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .story .story__content__timeline .story__content__timeline__card .story__content__timeline__card__content .story__content__timeline__card__content__picture img {
    width: 80px;
  }
}
.story .story__navigation {
  border-radius: 50%;
  height: 48px;
  width: 48px;
  padding: unset;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
}
.story .story__navigation.story__navigation--previous {
  left: 32px;
}
.story .story__navigation.story__navigation--next {
  right: 32px;
}
.story .story__navigation svg {
  height: 24px;
  width: 24px;
}
@media screen and (max-width: 768px) {
  .story .story__navigation {
    height: 40px;
    width: 40px;
  }
  .story .story__navigation.story__navigation--previous {
    left: 8px;
  }
  .story .story__navigation.story__navigation--next {
    right: 8px;
  }
  .story .story__navigation svg {
    height: 20px;
    width: 20px;
  }
}

.skills {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 64px;
  max-width: 960px;
  min-height: 500px;
  margin: 0 auto 32px auto;
}
@media screen and (max-width: 768px) {
  .skills {
    min-height: 900px;
  }
}
.skills .skills__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.skills .skills__content .skills__content__headers {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, auto);
  gap: 8px;
}
.skills .skills__content .skills__content__headers .skills__content__headers__element {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  padding: 8px 0;
  border-top: 1px solid var(--interactive-border);
  border-left: 1px solid var(--interactive-border);
  border-right: 1px solid var(--interactive-border);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-shadow: 0 0px 8px var(--interactive-border);
  background-color: var(--transparent-background);
  backdrop-filter: blur(8px);
  z-index: 1;
  cursor: pointer;
  color: var(--low-text);
  transition: all 200ms ease;
}
.skills .skills__content .skills__content__headers .skills__content__headers__element:hover {
  background-color: var(--item-hover);
}
.skills .skills__content .skills__content__headers .skills__content__headers__element:active {
  background-color: var(--item-active);
}
.skills .skills__content .skills__content__headers .skills__content__headers__element.--current {
  background-color: var(--subtle-background);
}
.skills .skills__content .skills__content__headers .skills__content__headers__element.--covered {
  transform: translateY(8px);
  z-index: 1;
}
.skills .skills__content .skills__content__headers .skills__content__headers__element img {
  width: 32px;
}
@media screen and (max-width: 768px) {
  .skills .skills__content .skills__content__headers .skills__content__headers__element.--covered > span {
    display: none;
  }
}
.skills .skills__content .skills__content__descriptions {
  position: relative;
  width: 100%;
}
.skills .skills__content .skills__content__descriptions .skills__content__descriptions__element {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 32px;
  box-sizing: border-box;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: var(--subtle-background);
  border: 1px solid var(--interactive-border);
  display: grid;
  grid-template-areas: "logo title" "logo text";
  gap: 32px;
  transition: all 200ms ease;
}
.skills .skills__content .skills__content__descriptions .skills__content__descriptions__element.--current {
  z-index: 2;
  box-shadow: 0 0px 8px var(--interactive-border);
}
.skills .skills__content .skills__content__descriptions .skills__content__descriptions__element.--covered {
  z-index: 1;
  opacity: 0;
}
.skills .skills__content .skills__content__descriptions .skills__content__descriptions__element.--changing > * {
  filter: blur(8px);
}
.skills .skills__content .skills__content__descriptions .skills__content__descriptions__element .skills__content__descriptions__element__img {
  width: 160px;
  grid-area: logo;
}
@media screen and (max-width: 768px) {
  .skills .skills__content .skills__content__descriptions .skills__content__descriptions__element .skills__content__descriptions__element__img {
    width: 120px;
    justify-self: center;
  }
}
.skills .skills__content .skills__content__descriptions .skills__content__descriptions__element .skills__content__descriptions__element__title {
  grid-area: title;
}
.skills .skills__content .skills__content__descriptions .skills__content__descriptions__element .skills__content__descriptions__element__text {
  grid-area: text;
}
@media screen and (max-width: 768px) {
  .skills .skills__content .skills__content__descriptions .skills__content__descriptions__element {
    grid-template-areas: "title" "logo" "text";
  }
}

.projects {
  min-height: 600px;
  display: grid;
  gap: 64px;
  position: relative;
}
@media screen and (max-width: 768px) {
  .projects {
    gap: 32px;
  }
}
.projects .projects__background {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -2;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}
.projects .projects__background::after {
  content: "";
  background-color: var(--action);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 32%;
}
.projects .projects__title {
  text-align: center;
}
@media screen and (max-width: 768px) {
  .projects .projects__title {
    text-align: left;
  }
}
.projects .projects__content {
  position: relative;
}
@media screen and (min-width: 769px) {
  .projects .projects__content {
    height: 584px;
  }
}
.projects .projects__content .projects__content__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;
}
@media screen and (min-width: 769px) {
  .projects .projects__content .projects__content__list {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    top: 0;
  }
}
@media screen and (max-width: 768px) {
  .projects .projects__content .projects__content__list {
    grid-template-columns: 1fr;
  }
}
.projects .projects__content .projects__content__list .projects__content__list__card {
  display: grid;
  box-sizing: border-box;
  gap: 16px;
  padding: 0 0 32px;
  border: 1px solid var(--static-border);
  border-radius: 8px;
  box-shadow: 0 0px 8px var(--interactive-border);
  background-color: var(--subtle-background);
  z-index: 2;
  filter: blur(0px);
  transition: all 300ms ease-out;
}
@media screen and (max-width: 768px) {
  .projects .projects__content .projects__content__list .projects__content__list__card {
    padding: 0 0 16px;
    gap: 8px;
  }
}
@media screen and (min-width: 769px) {
  .projects .projects__content .projects__content__list .projects__content__list__card {
    width: 140%;
    translate: -14% !important;
  }
  .projects .projects__content .projects__content__list .projects__content__list__card.--opening {
    z-index: 3 !important;
    scale: 1 !important;
    opacity: 100% !important;
    transition: all 100ms linear;
  }
  .projects .projects__content .projects__content__list .projects__content__list__card.--opening > h4,
  .projects .projects__content .projects__content__list .projects__content__list__card.--opening > p,
  .projects .projects__content .projects__content__list .projects__content__list__card.--opening > button,
  .projects .projects__content .projects__content__list .projects__content__list__card.--opening > h2,
  .projects .projects__content .projects__content__list .projects__content__list__card.--opening > img {
    opacity: 0%;
    transition: all 1000ms linear;
  }
  .projects .projects__content .projects__content__list .projects__content__list__card:nth-child(1) {
    scale: 0.9;
    translate: 160px 0;
    z-index: 1;
    opacity: 80%;
    filter: blur(0px);
  }
  .projects .projects__content .projects__content__list .projects__content__list__card:nth-child(1):hover {
    scale: 1;
    opacity: 100%;
    filter: blur(0px);
    z-index: 3;
  }
  .projects .projects__content .projects__content__list .projects__content__list__card:nth-child(1):hover + .projects__content__list__card {
    scale: 0.9;
    z-index: 2;
    opacity: 80%;
    filter: blur(0px);
  }
  .projects .projects__content .projects__content__list .projects__content__list__card:nth-child(1):hover + .projects__content__list__card + .projects__content__list__card {
    opacity: 40%;
    filter: blur(0px);
    scale: 0.8;
  }
  .projects .projects__content .projects__content__list .projects__content__list__card:nth-child(1):has(+ .projects__content__list__card + .projects__content__list__card:hover) {
    opacity: 40%;
    filter: blur(0px);
    scale: 0.8;
  }
  .projects .projects__content .projects__content__list .projects__content__list__card:nth-child(2):has(+ .projects__content__list__card:hover) {
    scale: 0.9;
    z-index: 1;
    opacity: 80%;
    filter: blur(0px);
  }
  .projects .projects__content .projects__content__list .projects__content__list__card:nth-child(3) {
    scale: 0.9;
    translate: -160px 0;
    z-index: 1;
    opacity: 80%;
    filter: blur(0px);
  }
  .projects .projects__content .projects__content__list .projects__content__list__card:nth-child(3):hover {
    scale: 1;
    opacity: 100%;
    filter: blur(0px);
    z-index: 2;
  }
}
.projects .projects__content .projects__content__list .projects__content__list__card h4,
.projects .projects__content .projects__content__list .projects__content__list__card p,
.projects .projects__content .projects__content__list .projects__content__list__card button {
  margin: 0 32px;
}
.projects .projects__content .projects__content__list .projects__content__list__card .projects__content__list__card__picture {
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  aspect-ratio: 1/1;
  width: 100%;
  object-fit: cover;
  grid-column: 1/2;
  grid-row: 1/2;
}
.projects .projects__content .projects__content__list .projects__content__list__card .projects__content__list__card__title {
  grid-column: 1/2;
  grid-row: 1/2;
  align-self: end;
  padding: 16px 32px 0;
  background: linear-gradient(to top, var(--subtle-background) 25%, transparent);
}
.projects .projects__content .projects__content__list .projects__content__list__card .projects__content__list__card__action {
  margin-top: 16px;
}

.ReactModal__Overlay {
  background-color: transparent !important;
  z-index: 100;
}

.project-modal {
  transition: all 200ms linear;
  box-sizing: border-box;
  border-radius: 8px;
  position: fixed;
  opacity: 0%;
  padding: 64px;
  color: var(--high-text);
  background: var(--subtle-background);
  text-align: center;
  border: 1px solid var(--static-border);
  box-shadow: 0 0 15px var(--static-border);
  display: grid;
  grid-template-columns: 3fr 2fr 2fr;
  gap: 32px;
  grid-template-areas: "modal-image modal-title modal-title" "modal-image modal-subtitle modal-date" "modal-image modal-description modal-description" "modal-image modal-type modal-technologies" "modal-image modal-functionalities modal-functionalities";
}
@media screen and (max-width: 768px) {
  .project-modal {
    padding: 16px;
    overflow: scroll;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas: "modal-title modal-title modal-title modal-close" "modal-image modal-image modal-image modal-image" "modal-subtitle modal-subtitle modal-date modal-date" "modal-description modal-description modal-description modal-description" "modal-type modal-type modal-technologies modal-technologies" "modal-functionalities modal-functionalities modal-functionalities modal-functionalities";
  }
}
.project-modal * {
  transition: all 200ms ease-out;
  text-align: left;
}
.project-modal p {
  max-width: unset;
}
.project-modal > .--category,
.project-modal .project-modal__picture {
  filter: blur(8px);
}
.project-modal > .--category {
  opacity: 0%;
  background-color: var(--item);
  width: 100%;
  padding: 16px 16px 32px 16px;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  border-left: 1px solid var(--static-border);
  box-sizing: border-box;
  position: relative;
}
@media screen and (max-width: 768px) {
  .project-modal > .--category {
    padding: 40px 8px 8px 8px;
  }
}
.project-modal > .--category .--category__name {
  position: absolute;
  bottom: 8px;
  right: 16px;
  font-size: 14px;
  color: var(--low-text);
  font-weight: 700;
  opacity: 72%;
  z-index: 0;
}
@media screen and (max-width: 768px) {
  .project-modal > .--category .--category__name {
    top: 8px;
    left: 8px;
  }
}
.project-modal .project-modal__navigation {
  position: fixed;
  top: 50%;
  transform: translate(0%, -50%);
  z-index: 10;
  padding: 0px;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  background-color: var(--transparent-background);
  backdrop-filter: blur(4px);
  border: 1px solid var(--interactive-border);
  box-shadow: 0px 0px 4px var(--interactive-border);
}
.project-modal .project-modal__navigation.--previous {
  left: 16px;
}
.project-modal .project-modal__navigation.--next {
  right: 16px;
}
@media screen and (max-width: 768px) {
  .project-modal .project-modal__navigation.--previous {
    left: 8px;
  }
  .project-modal .project-modal__navigation.--next {
    right: 8px;
  }
}
.project-modal .project-modal__navigation svg {
  height: 24px;
  width: 24px;
  color: var(--high-text);
}
.project-modal .project-modal__picture {
  opacity: 0%;
  grid-area: modal-image;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 16px;
}
@media screen and (max-width: 768px) {
  .project-modal .project-modal__picture {
    height: 240px;
  }
}
.project-modal .project-modal__title {
  grid-area: modal-title;
}
@media screen and (max-width: 768px) {
  .project-modal .project-modal__title {
    display: grid;
    gap: 16px;
  }
}
.project-modal .project-modal__title .project-modal__link {
  font-family: "Montserrat";
  font-size: 16px;
  margin-left: 32px;
  font-weight: 400;
  z-index: 1;
}
@media screen and (max-width: 768px) {
  .project-modal .project-modal__title .project-modal__link {
    margin: 0;
  }
}
.project-modal .project-modal__subtitle {
  grid-area: modal-subtitle;
}
.project-modal .project-modal__type {
  grid-area: modal-type;
}
.project-modal .project-modal__description {
  grid-area: modal-description;
}
.project-modal .project-modal__date {
  grid-area: modal-date;
}
.project-modal .project-modal__technologies {
  grid-area: modal-technologies;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(48px, 1fr));
  gap: 16px;
  justify-content: flex-end;
}
.project-modal .project-modal__technologies div {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
}
.project-modal .project-modal__technologies div img {
  width: 32px;
}
.project-modal .project-modal__functionalities {
  grid-area: modal-functionalities;
}
.project-modal .project-modal__close {
  position: absolute;
  opacity: 100%;
  top: 32px;
  right: 32px;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .project-modal .project-modal__close {
    grid-area: modal-close;
    position: sticky;
    top: 0;
    right: 0;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: var(--subtle-background);
    justify-self: end;
  }
}

.contact .contact__content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 64px;
}
@media screen and (max-width: 768px) {
  .contact .contact__content {
    grid-template-columns: 1fr;
    gap: 32px;
  }
}
.contact .contact__content .contact__content__image {
  position: relative;
  align-self: start;
}
@media screen and (max-width: 768px) {
  .contact .contact__content .contact__content__image {
    width: 72%;
    justify-self: center;
  }
  .contact .contact__content .contact__content__image img {
    width: 100%;
    margin: auto;
  }
}
.contact .contact__content .contact__content__image::after {
  content: "";
  background-color: var(--action);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 16%;
  border-radius: 50%;
}
.contact .contact__content .contact__content__image::before {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  box-shadow: 0 0 32px var(--action);
  border-radius: 50%;
  opacity: 32%;
}
.contact .contact__content .contact__content__image img {
  width: 100%;
}
.contact .contact__content .contact__content__ways {
  width: 100%;
  display: flex;
  gap: 32px;
  flex-direction: column;
}
.contact .contact__content .contact__content__ways .contact__content__ways__list {
  box-sizing: border-box;
  flex: 1;
  display: flex;
  gap: 16px;
  flex-direction: column;
  align-items: center;
}
.contact .contact__content .contact__content__ways .contact__content__ways__list .contact__content__ways__list__element {
  width: 100%;
  height: 48px;
  max-width: unset;
}
.contact .contact__content .contact__content__ways .contact__content__ways__list .contact__content__ways__list__element a {
  display: inline-block;
  background-color: var(--item);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  gap: 8px;
  text-decoration: none;
}
.contact .contact__content .contact__content__ways .contact__content__ways__list .contact__content__ways__list__element a:hover {
  background-color: var(--item-hover);
}
.contact .contact__content .contact__content__ways .contact__content__ways__list .contact__content__ways__list__element a:active {
  background-color: var(--item-active);
}
.contact .contact__content .contact__content__ways .contact__content__ways__list .contact__content__ways__list__element a svg:nth-child(3) {
  scale: 0.8;
  translate: -4px -4px;
}

.links {
  padding: 160px 128px 0px 128px;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .links {
    padding: 120px 16px;
  }
}
.links .links__content {
  display: flex;
  gap: 64px;
}
@media screen and (max-width: 768px) {
  .links .links__content {
    flex-direction: column;
    gap: 32px;
  }
}
.links .links__content .links__content__image {
  flex: 1;
}
.links .links__content .links__content__ways {
  flex: 1;
  display: flex;
  gap: 32px;
  flex-direction: column;
  align-items: center;
}
.links .links__content .links__content__ways .links__content__ways__list {
  box-sizing: border-box;
  flex: 1;
  display: flex;
  gap: 16px;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.links .links__content .links__content__ways .links__content__ways__list .links__content__ways__list__element {
  width: 100%;
  height: 48px;
  max-width: 600px;
}
.links .links__content .links__content__ways .links__content__ways__list .links__content__ways__list__element a {
  display: inline-block;
  background-color: var(--item);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  gap: 8px;
  text-decoration: none;
}
.links .links__content .links__content__ways .links__content__ways__list .links__content__ways__list__element a:hover {
  background-color: var(--item-hover);
}
.links .links__content .links__content__ways .links__content__ways__list .links__content__ways__list__element a:active {
  background-color: var(--item-active);
}
.links .links__content .links__content__ways .links__content__ways__list .links__content__ways__list__element a svg:nth-child(3) {
  scale: 0.8;
  translate: -4px -4px;
}

.settings-modal {
  width: 288px;
  transform: translate(-216px, 48px);
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;
  position: fixed;
  background-color: var(--subtle-background);
  padding: 24px;
  border-radius: 16px;
  border: 1px solid var(--static-border);
  box-shadow: 0 0px 8px var(--interactive-border);
  transition: all 200ms ease;
}
.settings-modal::before {
  content: "";
  background-color: var(--subtle-background);
  height: 16px;
  width: 16px;
  transform: rotate(45deg);
  position: absolute;
  right: 46px;
  top: -9px;
  border: 1px solid var(--static-border);
  box-shadow: 0 0px 8px var(--interactive-border);
}
.settings-modal::after {
  content: "";
  background-color: var(--subtle-background);
  height: 24px;
  width: 48px;
  position: absolute;
  right: 32px;
  top: 0px;
}
.settings-modal .settings-modal__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.settings-modal .settings-modal__content {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
}
.settings-modal .settings-modal__content .settings-modal__content__element {
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr 1fr;
  align-items: start;
}
.settings-modal .settings-modal__content .settings-modal__content__element .settings-modal__content__element__name {
  justify-self: start;
  padding-top: 8px;
}
.settings-modal .settings-modal__content .settings-modal__content__element .settings-modal__content__element__options {
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(3, 1fr);
  width: 136px;
}
.settings-modal .settings-modal__content .settings-modal__content__element .setting-option {
  background-color: var(--item);
  border: 1px solid transparent;
  padding: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 0 0px var(--active-border);
  justify-self: center;
}
.settings-modal .settings-modal__content .settings-modal__content__element .setting-option.--ruby {
  color: #fff;
  background-color: #e54666;
}
.settings-modal .settings-modal__content .settings-modal__content__element .setting-option.--ruby:hover {
  background-color: #ec5a72;
}
.settings-modal .settings-modal__content .settings-modal__content__element .setting-option.--indigo {
  color: #fff;
  background-color: #3e63dd;
}
.settings-modal .settings-modal__content .settings-modal__content__element .setting-option.--indigo:hover {
  background-color: #5472e4;
}
.settings-modal .settings-modal__content .settings-modal__content__element .setting-option.--jade {
  color: #fff;
  background-color: #29a383;
}
.settings-modal .settings-modal__content .settings-modal__content__element .setting-option.--jade:hover {
  background-color: #27b08b;
}
.settings-modal .settings-modal__content .settings-modal__content__element .setting-option.--mint {
  color: #000;
  background-color: #86ead4;
}
.settings-modal .settings-modal__content .settings-modal__content__element .setting-option.--mint:hover {
  background-color: #a8f5e5;
}
.settings-modal .settings-modal__content .settings-modal__content__element .setting-option.--amber {
  color: #000;
  background-color: #ffc53d;
}
.settings-modal .settings-modal__content .settings-modal__content__element .setting-option.--amber:hover {
  background-color: #ffd60a;
}
.settings-modal .settings-modal__content .settings-modal__content__element .setting-option.--olive {
  color: #000;
  background-color: #687066;
}
.settings-modal .settings-modal__content .settings-modal__content__element .setting-option.--olive:hover {
  background-color: #767d74;
}
.settings-modal .settings-modal__content .settings-modal__content__element .setting-option svg,
.settings-modal .settings-modal__content .settings-modal__content__element .setting-option span {
  height: 24px;
  width: 24px;
  color: var(--item-active);
  align-content: center;
}
.settings-modal .settings-modal__content .settings-modal__content__element .setting-option.--current {
  border: 1px solid var(--active-border);
  box-shadow: 0 0 16px var(--active-border);
  background-color: var(--action-hover);
}
.settings-modal .settings-modal__content .settings-modal__content__element .setting-option.--current:hover, .settings-modal .settings-modal__content .settings-modal__content__element .setting-option.--current:active {
  background-color: var(--action);
}
.settings-modal .settings-modal__content .settings-modal__content__element .setting-option.--current svg {
  color: var(--button-text);
}
.settings-modal .settings-modal__content .settings-modal__content__element .setting-option:hover {
  background-color: var(--item-hover);
}
.settings-modal .settings-modal__content .settings-modal__content__element .setting-option:hover svg {
  color: var(--button-text);
}
.settings-modal .settings-modal__content .settings-modal__content__element .setting-option:active {
  background-color: var(--item-active);
}

body:has(.header--locked) .settings-modal {
  transform: translate(-216px, 8px);
}
@media screen and (max-width: 768px) {
  body:has(.header--locked) .settings-modal {
    transform: translate(-216px, 34px);
  }
}

a {
  color: unset;
}

html {
  scroll-behavior: smooth;
}

* {
  transition-property: color, background-color;
  transition-duration: 200ms;
  transition-timing-function: ease-out;
}

body {
  background-color: var(--app-background);
  color: var(--high-text);
  font-family: "Montserrat";
  margin: 0;
  overflow-x: hidden;
  font-size: 16px;
}
@media screen and (max-width: 768px) {
  body {
    font-size: 14px;
  }
}

h1 {
  font-size: 40px;
  font-weight: 700;
  margin: 0;
  font-family: "Montserrat Alternates";
}
@media screen and (max-width: 768px) {
  h1 {
    font-size: 32px;
  }
}

h2 {
  font-size: 28px;
  font-weight: 700;
  margin: 0;
  font-family: "Montserrat Alternates";
}
@media screen and (max-width: 768px) {
  h2 {
    font-size: 24px;
  }
}

h4 {
  font-size: 20px;
  font-weight: 400;
  margin: 0;
  font-family: "Montserrat Alternates";
}
@media screen and (max-width: 768px) {
  h4 {
    font-size: 16px;
  }
}

p {
  margin: 0;
  max-width: 600px;
}

section {
  padding: 80px 128px;
  margin-bottom: 32px;
}
@media screen and (max-width: 768px) {
  section {
    padding: 64px 16px;
    width: 100vw;
    overflow: hidden;
    box-sizing: border-box;
  }
}
section h1 {
  z-index: 2;
}

button {
  height: 48px;
  padding: 0 24px;
  font-family: "Montserrat";
  font-size: 16px;
  background-color: var(--action);
  color: var(--button-text);
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  transition: all 200ms ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
@media screen and (max-width: 768px) {
  button {
    font-size: 14px;
  }
}
button:hover {
  background-color: var(--action-hover);
}
button:active {
  background-color: var(--action);
}

.close-icon {
  height: 24px;
  width: 24px;
}

